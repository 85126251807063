/*
 * mm-basic
 *
 * Here we overide the mmenu module mm-basic theme css
 *
 * [1] Correct the off canvas slide behaviour
 */

/* [1] */

html.mm-slide.mm-right.mm-opened .mm-menu {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

html.mm-slide.mm-right.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}

html.mm-slide.mm-top.mm-opened .mm-menu {
  -webkit-transform: translateY(-57px);
  -moz-transform: translateY(-57px);
  -ms-transform: translateY(-57px);
  -o-transform: translateY(-57px);
  transform: translateY(-57px);
}

html.mm-slide.mm-top.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-slideout,
  html.mm-right.mm-opening .pre-ajax {
    -webkit-transform: translate(-140px, 0);
    -moz-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    -o-transform: translate(-140px, 0);
    transform: translate(-140px, 0); 
  } 
}
@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-slideout,
  html.mm-right.mm-opening .pre-ajax {
    -webkit-transform: translate(-260px, 0);
    -moz-transform: translate(-260px, 0);
    -ms-transform: translate(-260px, 0);
    -o-transform: translate(-260px, 0);
    transform: translate(-260px, 0);
  }
}

.mm-menu {
  .mm-list {
    padding: 0;
    &.mm-panel {
      padding: 0;
    }
    > li {
      &:after {
        border-color: transparent;
        border-bottom-width: 0!important;
      }
    }
  }
}

.mm-list {
  > li {
    > a {
      font-size: 16px;
      padding: 17px;
      &:focus {
        outline-offset: 0;
        outline: none;
      }
      &:hover {
        background-color: black;
      }
    }

    > span {
      padding-top: 0; 
    }
  }
  a.mm-subclose:before {
    margin-bottom: -11px;
  }
  &.nav {
    > li {
      > a {
        &:focus {
          background-color: black;
        }
      }
    }
  }
}
